<template>
    <v-dialog v-model="dialog" scrollable width="90vw">
        <template v-slot:activator="{ on, attrs }">
            <v-btn small color="blue-grey" class="white--text" style="width: 100%" v-bind="attrs" v-on="on">
                <v-icon left>visibility</v-icon>
                Dados
            </v-btn>
        </template>

        <v-card>
            <v-card-title class="primary white--text"> {{ UUID }} </v-card-title>

            <v-card-text>
                <template v-if="items.length == 0">
                    <v-progress-linear :indeterminate="true" height="25" />
                    <v-skeleton-loader v-bind="attrs" type="table"></v-skeleton-loader>
                </template>

                <v-data-table v-if="items.length > 0" :headers="headers" :items="items" :items-per-page="-1" class="elevation-2">
                    <v-progress-linear v-slot:progress color="blue" indeterminate />
                    <template v-slot:item.value="{ item }">
                        <v-badge v-if="item.multiplier > 1" bordered :color="quality(item.quality)" :content="multiplierText(item.multiplier)" overlap>
                            <v-btn class="white--text" :color="quality(item.quality)" min-width="20vh" depressed>{{ item.value }}</v-btn>
                        </v-badge>
                        <v-btn v-else class="white--text" :color="quality(item.quality)" min-width="20vh" depressed>{{ item.value }}</v-btn>
                    </template>
                </v-data-table>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="dialog = false"> Fechar </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        UUID: {
            type: String,
            required: true,
        },
        online: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            dialog: false,

            timer: null,
            pending: false,
            headers: [
                { text: "Nome", align: "left", sortable: true, value: "name" },
                { text: "Valor", value: "value", align: "right" },
                { text: "Timestamp", value: "lastUpdate", align: "right" },
            ],
            items: [],
        };
    },
    computed: {
        multiplierText() {
            return (multiplier) => "* " + multiplier;
        },
        quality() {
            return (q) => (q == 0 && this.online === 1 ? `success` : this.online === 1 ? `warning` : `error`);
        },
    },
    created() {
        this.updateData();
        this.timer = setInterval(this.updateData, 5000);
    },
    methods: {
        updateData() {
            if (this.dialog && this.pending == false) {
                this.pending = true;
                this.$http
                    .get("api/v2/data?chargerUUID=" + this.UUID)
                    .then((result) => {
                        this.items = result;
                        this.pending = false;
                    })
                    .catch(() => {
                        this.items = [];
                        this.pending = false;
                    });
            }
        },
    },
    beforeDestroy() {
        clearInterval(this.timer);
    },
};
</script>